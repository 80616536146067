<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Perubahan Harga - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Pembelian / Perubahan Harga / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <form @submit.prevent="onConfirmSave(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :disabled="
              !(
                mark_edit &&
                (roles.includes('admin') || roles.includes('supervisor'))
              )
            "
            label="Update"
            type="submit"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PurchasePriceChanges' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-8">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>No Penerimaan</label>
                <InputText
                  v-model="form.rcv_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Supplier</label>
                <Dropdown
                  v-model="form.supp_id"
                  :filter="true"
                  :options="list_supplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Pilih Supplier"
                  :disabled="id"
                  :class="{
                    'p-invalid': v$.form.supp_id.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.supp_id.$invalid && submitted) ||
                    v$.form.supp_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supp_id.required.$message }}</small
                >
              </div>

              <div class="field col-12 md:col-4">
                <label>Tgl Terima</label>
                <Calendar
                  disabled
                  v-model="form.rcv_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>TGL SJ</label>
                <Calendar
                  v-model="form.sj_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  disabled
                  input-class="w-full"
                  :show-current="false"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No SJ</label>
                <InputText
                  v-model="form.sj_no"
                  disabled
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  disabled
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <DataTable
            :value="form.details"
            :scrollable="true"
            editMode="cell"
            class="p-datatable-sm"
            showGridlines
            :row-class="rowClass(data)"
            @cell-edit-complete="onCellEditComplete"
          >
            <template #empty>
              <div class="text-center">Tidak ada data.</div>
            </template>
            <template #loading>
              <div class="text-center">Memuat data, harap tunggu...</div>
            </template>
            <!-- <Column
              field="pr_no"
              header="No PEMBELIAN"
              class="bg-gray"
              style="min-width: 12rem"
            /> -->
            <Column
              field="item_sku"
              header="SKU"
              style="min-width: 8rem"
              class="bg-gray"
            />
            <!-- <Column
              field="item_barcode_1"
              class="bg-gray"
              header="Barcode"
              style="min-width: 8rem"
            /> -->
            <Column
              field="item_name"
              header="Deskripsi"
              class="bg-gray"
              style="min-width: 12rem"
            />
            <Column
              field="cost_s"
              header="HARGA NET"
              class="bg-gray"
              style="min-width: 9rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.cost_s) }}
              </template>
            </Column>
            <!-- <Column
              field="tax"
              header="ppn"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps"> {{ slotProps.data.tax }}% </template>
            </Column> -->
            <Column
              field="margin"
              header="MARGIN SKRG"
              class="bg-gray"
              style="min-width: 6rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ slotProps.data.margin }}%
              </template>
            </Column>
            <Column
              field="margin_pst"
              header="MARGIN PST"
              class="bg-gray"
              style="min-width: 6rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ slotProps.data.margin_pst }}%
              </template>
            </Column>
            <Column
              field="price_pst"
              header="HRG.JUAL SKRG"
              class="bg-gray"
              style="min-width: 9rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price_pst) }}
              </template>
            </Column>
            <Column
              field="price"
              header="HRG.JUAL PST"
              class="bg-gray"
              style="min-width: 9rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price) }}
              </template>
            </Column>

            <Column
              field="new_price"
              key="new_price"
              header="HRG.JUAL BARU"
              class="bg-gray"
              style="min-width: 9rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.new_price) }}
              </template>
              <template #editor="{ data }">
                <span v-if="data.mark == 3">{{
                  formatCurrency(data.new_price)
                }}</span>
                <div v-else class="p-inputgroup flex-1">
                  <span class="p-inputgroup-addon"> Rp </span>
                  <InputNumber
                    v-model="data.new_price"
                    class="w-full"
                    input-class="w-full"
                    autofocus
                    min="0"
                    :min-fraction-digits="0"
                    :max-fraction-digits="0"
                    locale="id-ID"
                    :inputStyle="{ textAlign: 'right' }"
                  />
                </div>
              </template>
            </Column>
            <Column
              v-if="
                mark_edit &&
                (roles.includes('admin') || roles.includes('supervisor'))
              "
              style="flex-grow: 1; justify-content: flex-end"
            >
              <template #body="{ data }">
                <Button
                  v-if="data.mark != 3"
                  type="button"
                  icon="pi pi-clone"
                  class="p-button-outlined p-button-primary p-button-text"
                  @click="onCopyPrice(data)"
                />
                <Button
                  v-if="data.mark != 3"
                  type="button"
                  icon="pi pi-times-circle"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onConfirmCancel(data)"
                />
              </template>
            </Column>
          </DataTable>
          <div class="flex mt-3">
            <div class="mr-3 flex">
              <div
                v-if="
                  list_mark_1.length > 0 &&
                  (roles.includes('admin') || roles.includes('supervisor'))
                "
              >
                <Button
                  icon="pi pi-sync"
                  :label="
                    isEditMark1 ? 'Batal' : 'Hrg. Jual Skrg < Hrg. Jual Preset'
                  "
                  class="p-button-danger mr-1 white-space-nowrap"
                  style="height: 100%"
                  @click="onSyncItem(1)"
                />
                <!-- :disabled="old_status_value === 'posted'" -->
              </div>
              <div
                v-else
                style="display: flex; align-items: center; width: 100%"
                class="status-badge bg-pink-100"
              >
                <!--  eslint-disable-next-line vue/no-parsing-error -->
                {{ 'Hrg. Jual Skrg < Hrg. Jual Preset' }}
              </div>
            </div>
            <div class="mr-3 flex">
              <div
                v-if="
                  list_mark_2.length > 0 &&
                  (roles.includes('admin') || roles.includes('supervisor'))
                "
              >
                <Button
                  icon="pi pi-sync"
                  :label="
                    isEditMark2 ? 'Batal' : 'Hrg. Jual Baru < Hrg. Jual Preset'
                  "
                  class="p-button-blue mr-1 white-space-nowrap"
                  style="height: 100%"
                  @click="onSyncItem(2)"
                />
                <!-- :disabled="old_status_value === 'posted'" -->
              </div>
              <div
                v-else
                style="display: flex; align-items: center; width: 100%"
                class="status-badge bg-blue-100"
              >
                <!--  eslint-disable-next-line vue/no-parsing-error -->
                {{ 'Hrg. Jual Baru < Hrg. Jual Preset' }}
              </div>
            </div>
            <div class="">
              <div
                style="display: flex; align-items: center; height: 100%"
                class="status-badge bg-green-100"
              >
                Item Dengan Perubahan Harga
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <Dialog
      header="Batalkan Perubahan Harga"
      v-model:visible="dialogCancel"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Perubahan Harga <strong>{{ formDetail.item_name }}</strong> akan
          dibatalkan. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Batalkan"
          icon="pi pi-times-circle"
          @click="onCancelItemPurchasePriceChanges"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      header="Update Perubahan Harga"
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span>Apakah anda yakin ingin<b> Update Perubahan Harga</b> ini?</span>
      </div>
      <template #footer>
        <Button
          label="Update"
          @click="save"
          class="p-button p-button-primary"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PurchaseOrderService from '@/services/PurchaseOrderService'
import PurchaseReceiveService from '@/services/PurchaseReceiveService'
import SupplierService from '@/services/SupplierService'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency } from '@/helpers/index'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'PurchaseReceive',
  components: {
    Hotkey,
  },
  data() {
    return {
      dialogSave: false,
      isEditMark1: false,
      isEditMark2: false,
      id: parseInt(this.$route.params.id),
      submitted: false,
      settingService: null,
      supplierService: null,
      purchaseOrderService: null,
      purchaseReceiveService: null,
      dialog: false,
      dialogItem: false,
      dialogCancel: false,
      isLoading: false,
      isLoadingItem: false,
      isLoadingSave: false,
      editMode: false,
      old_status_value: 'draft',
      tax: 0,
      items: [],
      list_supplier: [],
      list_term: [],
      list_satuan: [],
      list_satuan_unit: [],
      list_items: [],
      list_global_setting: [],
      list_purchase_order: [],
      list_details_purchase: [],
      form: {
        rcv_no: '',
        sj_no: '',
        rcv_at: new Date(),
        sj_at: null,
        supp_id: '',
        status: 'draft',
        details: [],
      },
      formTemp: [],
      formDetail: {},
      isCheck: false,
      listDeleted: [],
      PurchaseOrder: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    mark_edit() {
      if (this.form.details) {
        const mark = this.form.details.filter((item) => item.mark != 3)
        return mark.length > 0
      }
      return false
    },
    roles() {
      const user = JSON.parse(localStorage.getItem('user'))
      const roles = user.roles

      if (roles && roles.length > 0) {
        return roles.map((item) => item.name)
      }
      return []
    },
    list_mark_1() {
      const list_detail = this.form.details.filter((item) => item.mark === 1)
      return list_detail
    },
    list_mark_2() {
      const list_detail = this.form.details.filter((item) => item.mark === 2)
      return list_detail
    },

    list_status() {
      const list = [
        { value: 'printed', label: 'Printed', level: 0 },
        { value: 'posted', label: 'Posted', level: 1 },
      ]

      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: status.disabled
          ? status.disabled
          : old_status_level > status.level,
      }))
    },
    list_produk() {
      return this.form.details.map((x) => `${x.pr_id}${x.prd_id}`)
    },
  },
  created() {
    this.purchaseOrderService = new PurchaseOrderService()
    this.purchaseReceiveService = new PurchaseReceiveService()

    this.supplierService = new SupplierService()
  },
  async mounted() {
    if (this.id > 0) {
      this.loadData()
    }
    this.supplierService
      .getActive()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.purchaseReceiveService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          console.log(this.form.details)
          this.form.rcv_at = this.form.rcv_at ? new Date(this.form.rcv_at) : ''
          this.old_status_value = this.form.status
          // Filter out items with qty == 0
          this.form.details = this.form.details.filter((item) => item.qty !== 0)
          this.formTemp = this.form.details.map((item) => ({
            id: item.id,
            item_id: item.item_id,
            new_price: item.new_price,
            old_margin: item.margin,
          }))
        })
        .catch((err) => {
          errorHandler(err, 'Data Perubahan Harga', this)
        })
        .finally(() => (this.isLoading = false))
    },

    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.dialogSave = true
    },
    async onSyncItem(mark = 1) {
      if ((this.isEditMark1 && mark == 1) || (this.isEditMark2 && mark == 2)) {
        await this.form.details.map((item) => {
          if (item.mark === mark) {
            const idx = this.formTemp.findIndex(
              (element) => element.id === item.id
            )
            if (idx != -1) {
              this.form.details[idx].new_price = this.formTemp[idx].new_price
            }
          }
        })
        this.isEditMark1 = mark === 1 ? false : this.isEditMark1
        this.isEditMark2 = mark === 2 ? false : this.isEditMark2
      } else {
        this.form.details.forEach((item) => {
          if (item.mark != mark) {
            return
          } else {
            item.new_price = item.price
            this.isEditMark1 = mark === 1 ? true : this.isEditMark1
            this.isEditMark2 = mark === 2 ? true : this.isEditMark2
          }
        })
      }
    },
    onCellEditComplete(event) {
      let { data, newValue } = event
      const idx = this.form.details.findIndex((item) => item.id === data.id)
      if (idx !== -1) {
        // Update the new price
        this.margin_old = this.form.details[idx].margin
        this.form.details[idx].new_price = newValue

        // Calculate the new margin
        const cost_s = this.form.details[idx].cost_s
        const new_price = parseFloat(newValue)
        let margin = 0.0
        if (new_price > 0.0) {
          margin =
            Math.round(
              (((new_price - cost_s) / new_price) * 100 + Number.EPSILON) * 100
            ) / 100
        }

        // Update the margin
        this.form.details[idx].margin = margin
      }
    },

    // Utility function to round numbers to a specific precision
    round(value, precision) {
      let multiplier = Math.pow(10, precision || 0)
      return Math.round(value * multiplier) / multiplier
    },
    save() {
      const form = {
        details: this.form.details.map((item) => {
          return {
            id: item.id,
            rcv_id: item.rcv_id,
            item_id: item.item_id,
            new_price: item.new_price || 0,
          }
        }),
      }
      this.isLoadingSave = true
      this.purchaseReceiveService
        .updatePriceChange(this.id, form)
        .then((res) => {
          if (res.data.status === 1) {
            this.loadData()
            this.$toast.add({
              severity: 'success',
              summary: 'Perubahan Harga',
              detail: 'Data berhasil diupdate.',
              life: 3000,
            })
            this.dialogSave = false
            this.isEditMark1 = false
            this.isEditMark2 = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Perubahan Harga', this)
        })
        .finally(() => {
          this.isLoadingSave = false
        })
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },

    onConfirmCancel(data) {
      this.formDetail = data
      this.onCancelItemPurchasePriceChanges()
      // this.dialogCancel = true
    },
    onCopyPrice(data) {
      const idx = this.form.details.findIndex((item) => item.id === data.id)
      const old_price = data.price
      if (idx != -1) {
        this.form.details[idx].new_price = old_price
        this.form.details[idx].margin = this.formTemp[idx].old_margin
      }
    },
    onCancelItemPurchasePriceChanges() {
      const idx = this.formTemp.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (idx !== -1) {
        this.form.details[idx].new_price = this.formTemp[idx].new_price
        this.form.details[idx].margin = this.formTemp[idx].old_margin
      }
      this.dialogCancel = false
    },
    rowClass(data) {
      if (data.mark === 3) {
        return 'bg-green-100'
      }
      if (data.mark === 2) {
        return 'bg-blue-100'
      }
      if (data.mark === 1) {
        return 'bg-pink-100'
      }
      if (data.mark === 0) {
        return 'default-row'
      }
    },
  },
  validations() {
    return {
      form: {
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>

<style>
.default-row .bg-gray {
  background-color: #f8f9fa;
}
</style>
